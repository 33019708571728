import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#333333',
                secondary: '#002B49',
                accent: '#00B3E3',
                error: '#9F2242',
            },
        },
    },
});
