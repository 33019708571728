import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router.js'

Vue.config.productionTip = false

Vue.$router = router;

new Vue({
    vuetify,
    router,
    render: h => h(App),
}).$mount('#app')
