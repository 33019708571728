<template>
  <v-app id="app">
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'App'
  }
</script>

<style lang="scss">
  @import '@/style.scss';

  body .theme--light.v-application {
    background-color: $background-color;
  }

  .v-main__wrap {
    margin-top: 20px;
  }
</style>
