<template>
  <div>
    <v-card class="home-card text-center">
      <v-card-text>
        <v-alert v-model="errorAlert" class="text-left" dense type="error">There was an error redirecting to {{ siteName }}</v-alert>
        <img alt="Seacoast" src="./../assets/seacoast.png" class="mr-4">
        <img alt="Amwins Digital" src="./../assets/amwins_logo--digital.svg">
        <h2 class="my-4">
          Seacoast Brokers is now Amwins Access Digital!
        </h2>
        <p v-if="thankYouMessage">
          {{ thankYouMessage }}
        </p>
        <h1>
          {{ redirectText }}
        </h1>
        <h3>
          Or click <a @click="navigate()">Here</a> to continue to {{ siteName }}
        </h3>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        redirectMessage: '',
        siteName: '',
        redirectUrl: '',
        thankYouMessage: '',
        errorAlert: false
      }
    },
    created() {
      this.getRedirectUrl()
      this.countdownTimer()
    },
    computed: {
      redirectText() {
        return this.redirectMessage
      }
    },
    methods: {
      countdownTimer() {
        let navigationTimer = null;
        let counter = 5
        this.redirectMessage = `Redirecting you automatically to ${this.siteName} in ${counter} seconds...`
        navigationTimer = setInterval(() => {
          counter--;
          this.redirectMessage = `Redirecting you automatically to ${this.siteName} in ${counter} seconds...`;
          if (counter < 1) {
            clearInterval(navigationTimer);
            this.navigate()
          }
        },
          1000);
      },
      getRedirectUrl() {
        if (window.location.href.includes('portal.seacoastbrokers.com') ||
            window.location.href.includes('portal.tridentclaims.com') ||
            window.location.href.includes('portal-test.seacoastbrokers.com') ||
            window.location.href.includes('portal-test.tridentclaims.com')
            ) {
          this.redirectUrl = 'https://personal-lines.amwins.com'
          this.siteName = 'Amwins Personal Lines'
        }
        else if (window.location.href.includes('insured.seacoastbrokers.com')) {
          this.redirectUrl = 'https://personal-lines-insured.amwins.com'
          this.siteName = 'Amwins Insured Portal'
        }
        else {
          // For now, we're defaulting to the Amwins site if no match was found
          this.redirectUrl = 'https://www.amwins.com/solutions/small-accounts/personal-lines'
          this.siteName = 'Amwins Personal Lines'
        }
      },
      navigate() {
        if (this.redirectUrl) {
          window.location = this.redirectUrl
        } else {
          this.errorAlert = true
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '@/style.scss';

  .home-card {
    margin-left: auto;
    margin-right: auto;
    width: 800px;
  }
</style>
